import { string, bool, shape } from 'prop-types'
import { addressType } from './address'

export const cardInfoType = shape({
  stripe_last4: string,
  stripe_card_type: string,
  payment_method: string,
  can_remove_card: bool,
  billing_address: addressType
})

export const companyDetailsType = shape({
  business: string,
  line1: string,
  line2: string,
  city: string,
  state: string,
  zip: string,
  vat: string,
  additional_info: string,
  showEmail: bool
})
