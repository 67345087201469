import { string, number, shape, bool } from 'prop-types'

export const purchaseInfoType = shape({
  name: string.isRequired,
  price: number.isRequired,
  selector: string,
  ct: string,
  mt: string,
  et: string,
  hasTrial: bool,
  block_alipay: bool
})

export const alipayErrorType = shape({
  id: string.isRequired,
  message: string.isRequired
})
