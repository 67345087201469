import { string, shape } from 'prop-types'

export const addressType = shape({
  firstName: string,
  lastName: string,
  address_line1: string,
  address_line2: string,
  address_city: string,
  address_state: string,
  address_zip: string,
  address_country: string
})
