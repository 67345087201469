import {
  shape,
  string,
  func,
  bool,
  number,
  object,
  oneOfType,
  oneOf
} from 'prop-types'

export const tableColumnType = shape({
  accessor: string,
  formatter: func,
  name: oneOfType([string, object, func]),
  subtitle: oneOfType([string, object]),
  sortable: bool,
  sortAccessor: string, // optional, use if the sort accessor is different from the display accessor
  dataId: string, // used to target header cells in Pendo
  tooltipContent: oneOfType([string, object]),
  textAlign: oneOf(['left', 'right', 'center']),
  disableColumnSelect: bool,
  index: number
})

export const sortType = shape({
  column: oneOfType([string, func]),
  direction: oneOfType([string, func]),
  handleClick: func
})

// Types for Custom Table Options
export const addNewItemType = shape({
  component: oneOfType([object, bool]),
  dataId: string,
  handleClick: func,
  showOnEmpty: bool,
  text: string
})
