import { shape, bool, object, func, any, number } from 'prop-types'

/*
 *               _
 *              | |
 *           ___| |_ ___  _ __
 *          / __| __/ _ \| '_ \
 *          \__ \ || (_) | |_) |
 *          |___/\__\___/| .__/
 *                       | |
 *                       |_|
 *
 * Please do not add to this file.
 * Additions to this file will get pulled into the main JS bundle where they probably don't need to be
 * Please create a new file, add your methods/constants to that and import just the code you need.
 *
 * For more info see https://github.com/Junglescout/docs/blob/master/client/Code-Splitting.md
 */

export * from './common'
export * from './account'
export * from './address'
export * from './memberships'
export * from './registrations'

export const loadingType = shape({
  isLoading: bool,
  completed: bool
})

export const formikType = shape({
  dirty: bool,
  errors: object,
  handleBlur: func,
  handleChange: func,
  handleReset: func,
  handleSubmit: func,
  isSubmitting: bool,
  isValid: bool,
  isValidating: bool,
  resetForm: func,
  setErrors: func,
  setFieldError: func,
  setFieldTouched: func,
  submitForm: func,
  submitCount: number,
  setFieldValue: func,
  setStatus: func,
  setSubmitting: func,
  setTouched: func,
  setValues: func,
  status: any,
  touched: object,
  values: object,
  validateForm: func,
  validateField: func
})

export const historyType = shape({
  push: func
})
